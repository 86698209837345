import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Complete Dental Frontend',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://other-birman.k8s-test.emendis.nl/api',
  sentryUrl: 'https://617d81e820bb4e67baf19658f3482343@o4504360297496576.ingest.sentry.io/4504377841418240',
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://dental.complete.test.emendis.nl',
  threeShapeLogoutUrl: `https://staging-identity.3shape.com/connect/endsession`,
};
